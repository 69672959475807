///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

	#footer {
		.icons {
			color: _palette(fg-light);
		}

		.copyright {
			color: _palette(fg-light);
			font-family: _font(family-heading);
			font-size: 0.5em;
			font-weight: _font(weight-heading);
			letter-spacing: _font(kerning-heading);
			text-transform: uppercase;
		}

		body.single & {
			text-align: center;
		}
	}

	.icon-custom-color-sidebar {
		color: #ffffff; /* Set initial color, use 'initial' or the default color you want */
		transition: color 0.2s ease; /* Add a transition for smooth color change */
	  }

	  .icon-custom-color-sidebar:hover {
		color: blue; /* Change the color to blue on hover */
	  }

	  .icon-custom-color-md {
		color: #000000b0; /* Set initial color, use 'initial' or the default color you want */
		transition: color 0.2s ease; /* Add a transition for smooth color change */
	  }

	  .icon-custom-color-md:hover {
		color: blue; /* Change the color to blue on hover */
	  }