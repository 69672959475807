// About page (/about)

.markdown {

  p {
    margin: auto;
  }

  h1 {
    font-size: .8em;
    margin-top: 3em;
  }
}

.markdown-container {
  max-height: 65vh; /* Adjust the height as needed */
  overflow-y: auto;
  padding: 10px; /* Optional padding for better appearance */
}

.resume-container {
  max-height: 60vh; /* Adjust the height as needed */
  overflow-y: auto;
  padding: 10px; /* Optional padding for better appearance */
}