
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 20px;
  overflow-y: auto;
  max-height: 63vh;
}

.photo-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
  transition: opacity 0.3s ease;
}

.photo-item:hover {
  transform: scale(1.3);
}

.photo-item img:hover {
  transform: none;
  opacity: 1;
}


